<template>
  <div class="memhpage">
    <div class="bgsw">
      <div class="bgtit">首页预览</div>
      <div class="showimg">
        <img class="hbgimg" v-if="data.bgimg.length>0" :src="data.bgimg" />
        <div class="nrw">
          <img class="htrbg" src="@/assets/img/htr-bg.png" />
          <div class="muw">
            <div class="mulis">
              <div class="midbg"></div>
              <div class="mutit">{{data.bklis[0].title}}</div>
              <div class="mutxt">{{data.bklis[0].content}}</div>
              <div class="mubtn">{{data.bklis[0].btn}}</div>
            </div>
            <div class="mulis">
              <div class="midbg"></div>
              <div class="mutit">{{data.bklis[1].title}}</div>
              <div class="mutxt">{{data.bklis[1].content}}</div>
              <div class="mubtn">{{data.bklis[1].btn}}</div>
            </div>
            <div class="mulis">
              <div class="midbg"></div>
              <div class="mutit">{{data.bklis[2].title}}</div>
              <div class="mutxt">{{data.bklis[2].content}}</div>
              <div class="mubtn">{{data.bklis[2].btn}}</div>
            </div>
          </div>
          <div class="attenw">
            <img class="hmidbg" src="@/assets/img/hmid-bg.png" />
            <img class="attimg" src="@/assets/img/hattention.png" />
            <div class="attencon">{{data.atten}}</div>
          </div>
        </div>
      </div>
      <div class="upbtnw">
        <upload class="bgubtn" btnTxt="点击上首页背景" action="upload_img.html" @upSuccess="upSuccess"></upload>
        <div class="sizetips">建议尺寸：宽度 750px 高度：750px ~ 1200px (首页背景色：#00060d)</div>
      </div>
    </div>
    <div class="bgsw">
      <div class="bgtit">版块设置</div>
      <div class="bklis">
        <Input type="text" class="bktit" placeholder="标题" maxlength="12" v-model="data.bklis[0].title" />
        <Input type="textarea" class="bknr"  :autosize="{minRows: 4,maxRows: 4}" placeholder="内容" maxlength="150" v-model="data.bklis[0].content" />
        <Input type="text" class="bkbtn" placeholder="按钮" maxlength="12" v-model="data.bklis[0].btn" />
      </div>
      <div class="bklis">
        <Input type="text" class="bktit" placeholder="标题" maxlength="12" v-model="data.bklis[1].title" />
        <Input type="textarea" class="bknr"  :autosize="{minRows: 4,maxRows: 4}" placeholder="内容" maxlength="150" v-model="data.bklis[1].content" />
        <Input type="text" class="bkbtn" placeholder="按钮" maxlength="12" v-model="data.bklis[1].btn" />
      </div>
      <div class="bklis">
        <Input type="text" class="bktit" placeholder="标题" maxlength="12" v-model="data.bklis[2].title" />
        <Input type="textarea" class="bknr"  :autosize="{minRows: 4,maxRows: 4}" placeholder="内容" maxlength="150" v-model="data.bklis[2].content" />
        <Input type="text" class="bkbtn" placeholder="按钮" maxlength="12" v-model="data.bklis[2].btn" />
      </div>
    </div>
    <div class="bgsw">
      <div class="bgtit">特别注意</div>
      <div class="attw">
        <Input type="textarea" class="bknr"  :autosize="{minRows: 4,maxRows: 4}" placeholder="内容" maxlength="500" v-model="data.atten" />
      </div>
    </div>
    <div class="bgsw">
      <div class="bgtit"></div>
      <Button type="info" style="width: 120px" @click="save_edit">保存</Button>
    </div>
    <Spin size="large" fix v-if="page_loading"></Spin>
  </div>
</template>
<script>
import upload from "@/components/public/upload";
export default {
  components:{upload},
  name: 'Memhpage',
  data(){
    return {
      data:{
        bgimg:'',
        bklis:[
          {title:'',content:'',btn:''},
          {title:'',content:'',btn:''},
          {title:'',content:'',btn:''}
        ],
        atten:''
      },
      page_loading:false,
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.page_loading=true;
      this.requestApi('/adm/mempgset.html').then(function (res){
        _this.page_loading=false;
        if(res.status==1) _this.data=res.data;
      })
    },
    /**
     * 上传成功
     */
    upSuccess:function(e){
      this.data.bgimg = e.response.url;
    },
    /**
     * 保存选项
     */
    save_edit:function (){
      var _this=this;
      this.page_loading=true
      this.requestApi('/adm/save_mhpage.html',this.data).then(function (res){
        _this.page_loading=false
        if(res.status==1) _this.tipSuccess(res.msg);
        else _this.alertError(res.msg);
      })
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/memhpage";
</style>